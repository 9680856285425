<template>
<div>
 <div class="row">
  <div class="col-sm-12">
     <!-- <p style="text-align: right; margin-top: -25px;"><B>Tracking No: {{ order.order_env_number }}</B></p> -->
  </div>
 </div>
 <div class="row">
              
    <div class="col-sm-12 col-md-12">

      <!-- The Timeline -->

      <ul class="timeline">

        <!-- Item 1 -->
        <li v-for="(item, index) in diputedata" :key="index">
          <div :class="directionClass(item.dispute_status)">
            <div class="flag-wrapper">
              <span class="flag">{{timelineHeading(item.dispute_status)}}</span>
              <span class="time-wrapper"><span class="time">{{item.date}}</span></span>
            </div>
            <div class="desc"><b>{{item.dispute_reason.reason}} </b> <br> {{item.discribe_reason}}</div>
          </div>
        </li>         
        
      </ul>
      
      
      <!-- return pending -->
      <!-- <p v-if="history.length === 1" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Pending</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[0].date }} your Return order has been pending. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 1" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Merchanet Approve</B></a>
      </p>

      <p v-if="history.length === 1" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Merchant Reject</B></a>
      </p>
      
      <p v-if="history.length === 1" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Send to Admin</B></a>
      </p>

      <p v-if="history.length === 1" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Admin Approve</B></a>
      </p> -->


      <!-- return merchanet approve -->
      <!-- <p v-if="history.length === 2" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Pending</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[0].date }} your Return order has been pending. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 2" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Merchanet Approve</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[1].date }} your Return order has been merchanet approve. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 2" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Merchant Reject</B></a>
      </p>
      
      <p v-if="history.length === 2" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Send to Admin</B></a>
      </p>

      <p v-if="history.length === 2" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Admin Approve</B></a>
      </p> -->


      <!-- return merchant reject -->
      <!-- <p v-if="history.length === 3" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Pending</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[0].date }} your Return order has been pending. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 3" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Merchanet Approve</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[1].date }} your Return order has been merchanet approve. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 3" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Merchant Reject</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[2].date }} your Return order has been Merchant Reject. Thank you for shopping with us.</small></a>
      </p>
        
      <p v-if="history.length === 3" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Send to Admin</B></a>
      </p>

      <p v-if="history.length === 3" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Admin Approve</B></a>
      </p> -->


      <!-- return send to admin-->
      <!-- <p v-if="history.length === 4" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Pending</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[0].date }} your Return order has been pending. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 4" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Order has been Merchanet Approve</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[1].date }} your Return order has been merchanet approve. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 4" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Order has been Merchant Reject</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[2].date }} your Return order has been Merchant Reject. Thank you for shopping with us.</small></a>
      </p>
        
      <p v-if="history.length === 4" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Send to Admin</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[3].date }} your Return order has been Send to Admin. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 4" class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Admin Approve</B></a>
      </p> -->


      <!-- return admin approve -->
      <!-- <p v-if="history.length === 5" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Pending</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[0].date }} your Return order has been pending. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 5" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Merchanet Approve</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[1].date }} your Return order has been merchanet approve. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 5" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Merchant Reject</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[2].date }} your Return order has been Merchant Reject. Thank you for shopping with us.</small></a>
      </p>
        
      <p v-if="history.length === 5" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Send to Admin</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[3].date }} your Return order has been Send to Admin. Thank you for shopping with us.</small></a>
      </p>

      <p v-if="history.length === 5 && history[4].order_line_status === 40" class="p-shipping-details"><img src="assets/image/sample_product_images/confirm1.png" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a><B>Your Return Order has been Admin Approve</B></a>
        <br/><a style="margin-left: 42px;"><small>{{ history[4].date }} your Return order has been Admin Approve. Thank you for shopping with us.</small></a>
      </p> -->

    </div>

  </div>
</div> 
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder";
export default {
  mixins: [mixinOrder],
  props: {
    order: Object
  },
  data(){
    return {
      history: [],
      diputedata: []
    }
  },
  created() {
    this.getReturnOrderStatusHistoryDetails();
  },
  methods: {
    getReturnOrderStatusHistoryDetails: async function () {
      if (this.order._id) {
         try {
            let id = this.order._id;
            let responce = await this.getReturnOrderStatusHistory(id);
            this.diputedata = responce;
            this.history = responce.map(obj=>{
              let dats = obj.created_date.split("T");
              obj.date = dats[0];
              return obj;
            });
          } catch (error) {
            console.log(error); 
          }
      }
    },
    directionClass(status){
      switch (status) {
        case 1:
          return 'direction-l'
          break;

        case 10:
          return 'direction-r'
          break;
        
        case 20:
          return 'direction-r'
          break;

        case 30:
          return 'direction-r'
          break;

        case 40:
          return 'direction-r'
          break;

        case 50:
          return 'direction-l'
          break;

        case 60:
          return 'direction-r'
          break;

        case 70:
          return 'direction-r'
          break;
      
        default:
          return 'direction-l'
      }
    },
    timelineHeading(status){
 
      switch (status) {
        case 1:
          return 'Dispute Opened'
          break;

        case 2:
          return 'Dispute in Progress'
          break;

        case 10:
          return 'Merchanet Approved'
          break;

        case 20:
          return 'Merchant Rejected'
          break;

        case 30:
          return 'Escalated to Cochchi Admin'
          break;

        case 31:
          return 'Admin in Progress'
          break;

        case 40:
          return 'Admin Approved Return Order'
          break;

        case 50:
          return 'Pickup Return Order'
          break;

        case 60:
          return 'Merchant Return Approve'
          break;

        case 70:
          return 'Merchant Money Pass'
          break;
      
        default:
          break;
      }
    }
  }
}
</script>
<style scoped>
/* ================ The Timeline ================ */

  .timeline {
    position: relative;
    width: 660px;
    /* margin: 0 auto; */
    margin-top: 20px;
    padding: 1em 0;
    list-style-type: none;
  }

  .timeline:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: rgb(158, 158, 158);
    background: -moz-linear-gradient(top, rgba(158, 158, 158,0) 0%, rgb(158, 158, 158) 8%, rgb(158, 158, 158) 50%, rgba(158, 158, 158,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
    background: -webkit-linear-gradient(top, rgba(158, 158, 158,0) 0%, rgb(158, 158, 158) 8%, rgb(158, 158, 158) 50%, rgba(158, 158, 158,0) 100%);
    background: -o-linear-gradient(top, rgba(158, 158, 158,0) 0%, rgb(158, 158, 158) 8%, rgb(158, 158, 158) 50%, rgba(158, 158, 158,0) 100%);
    background: -ms-linear-gradient(top, rgba(158, 158, 158,0) 0%, rgb(158, 158, 158) 8%, rgb(158, 158, 158) 50%, rgba(158, 158, 158,0) 100%);
    background: linear-gradient(to bottom, rgba(158, 158, 158,0) 0%, rgb(158, 158, 158) 8%, rgb(158, 158, 158) 50%, rgba(158, 158, 158,0) 100%);
    
    z-index: 5;
  }

  .timeline li {
    padding: 1em 0;
  }

  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .direction-l {
    position: relative;
    width: 300px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 300px;
    float: right;
  }

  .flag-wrapper {
    position: relative;
    display: inline-block;
    
    text-align: center;
  }

  .flag {
    position: relative;
    display: inline;
    background: rgb(248,248,248);
    padding: 6px 10px;
    border-radius: 5px;
    
    font-weight: 600;
    text-align: left;
  }

  .direction-l .flag {
    -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }

  .direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -40px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: rgb(52, 122, 183);
    border-radius: 10px;
    border: 10px solid rgb(52, 122, 183);
    z-index: 10;
  }

  .direction-r .flag:before {
    left: -40px;
  }

  .direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }

  .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: inline;
    
    line-height: 1em;
    font-size: 0.66666em;
    color: rgb(250,80,80);
    vertical-align: middle;
  }

  .direction-l .time-wrapper {
    float: left;
  }

  .direction-r .time-wrapper {
    float: right;
  }

  .time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248,248,248);
  }

  .desc {
    margin: 1em 0.75em 0 0;
    
    font-size: 0.77777em;
    font-style: italic;
    line-height: 1.5em;
  }

  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }

  /* ================ Timeline Media Queries ================ */

  @media screen and (max-width: 660px) {

  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  .timeline li {
    padding: 2em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;

    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    background: rgb(255,255,255);
    z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
    left: 50%;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-left: -9px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid rgb(255,80,80);
    z-index: 10;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255,255,255);
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    background: rgb(245,245,245);
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,0.20);
    -moz-box-shadow: 0 0 1px rgba(0,0,0,0.20);
    box-shadow: 0 0 1px rgba(0,0,0,0.20);
    
    z-index: 15;
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;
    
    z-index: 15;
  }

  }

  @media screen and (min-width: 400px ?? max-width: 660px) {

  .direction-l .desc,
  .direction-r .desc {
    margin: 1em 4em 0 4em;
  }

  }
</style>

