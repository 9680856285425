<template>
	<div v-if="this.order[0]" class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
				
					<div class="account-border">
          <div class="well" style="min-height: auto !important;">										
                
						<div class="row">
              
							<div class="col-sm-12 customer-login">
                <div class="col-md-6" style="padding-left: 0px;">
                  <h2>Order Details</h2>
                </div>
								
                <div class="col-md-6 text-right" style="padding-right: 0px;">
                  <button data-toggle="modal" data-target="#mdlDisputeChat" class="btn btn-primary">Dispute Chat</button>
                </div>
                <hr/>
                
              </div>

            </div>

            <div class="row">
              
							<div class="col-sm-3">
								<img width="100px" :src="order[0].simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">           

                <ul class="product-options clearfix">
                  <li class="radio active">
                    <h4><b>{{ order[0].simple_product_id.product_id.product_name }}</b></h4>
                    <!-- <h4><b>{{ order[0].simple_product_id.product_id.product_name }} / {{ order[0].simple_product_id.attributes[0].attribute_value_id.lable }} / {{  order[0].simple_product_id.attributes[1].attribute_value_id.lable }}</b></h4> -->
                  </li>

					      	<p class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30 Days Buyer Protection</p>
			      			<!-- <p class="p-shipping-details"><img src="assets/image/sample_product_images/logistics-delivery-truck-in-movement.svg" style="width:28px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dispute Order Status : {{ order[0].dispute_status}}</p> -->
					      	<p class="p-shipping-details"><img src="assets/image/sample_product_images/money.svg" style="width:25px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment Method: Cash on Delivery</p>

                </ul>
                
              </div>
              
              <div class="col-sm-9">
                 <div class="row">
                  <div class="col-sm-12">
                    <!-- <h4>Delivery Partner Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order[0].delivery_partner.first_name}} {{ order[0].delivery_partner.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].delivery_partner.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order[0].delivery_partner.email}}</p>                                        
                      </li>
                    </ul> -->

                  </div>
                </div>  
                <!-- <hr/> -->
                <div class="row">
                  <div class="col-sm-12">
                    <h4>Merchant Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order[0].merchant_id.first_name}} {{ order[0].merchant_id.last_name}}</p>
                      </li>
                    </ul>

                  </div>

                  <div class="col-sm-12 col-md-12">
                    <h4>Track Return Order</h4>
                    <ReturnTrackOrder :order="order[0]"/>
                  </div>
                  
                </div>  
              </div>

              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6">
                     <h4>Billing Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Order Number : {{ order[0].order_id.order_env_number}}</p>
                      </li>
                      <li class="radio active">
                        <p>Billing Name : {{ order[0].order_id.billing_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].order_id.billing_mobile}}</p>                    
                      </li>
                      <!-- <li class="radio active">
                        <p>Address : {{ order[0].order_id.address_id.address}} {{ order[0].order_id.address_id.postal_code}}</p>                                        
                      </li> -->
                      <li class="radio active">
                        <p>Date : {{ order[0].order_id.date}}</p>                                        
                      </li>
                    </ul>
                  </div>
                  <div class="col-sm-6">
                     <h4>Dispute Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
					        			<img width="100px" :src="order[0].images[0]" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">                                   
                      </li>
                      <li class="radio active">
                        <p>Discribe Reason : {{ order[0].discribe_reason}}</p>
                      </li>
                      <li class="radio active">
                        <!-- <p>Dispute Status : <b>{{ order[0].dispute_status}}</b></p> -->
                        <p>Dispute Status :  <span class="badge" style="background-color: rgb(241, 202, 22); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Dispute Opened'">
                    <B>Dispute Opened</B>
                  </span>

                  <span class="badge" style="background-color: rgb(255, 163, 0); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Dispute in Progress'">
                    <B>Dispute in Progress</B>
                  </span>

                   <span class="badge" style="background-color: green;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Merchanet Approved'">
                    <B>Merchanet Approved</B>
                  </span>

                  <span class="badge" style="background-color: red;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Merchant Rejected'">
                    <B>Merchant Rejected</B>
                  </span>

                  <span class="badge" style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Escalated to Cochchi Admin'">
                    <B>Escalated to Cochchi Admin</B>
                  </span>

                  <span class="badge" style="background-color: rgb(163, 43, 185); border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Admin in Progress'">
                    <B>Admin in Progress</B>
                  </span>

                  <span class="badge" style="background-color: green;border-radius: 4px; font-size: 10px; padding: 4px 6px;"
                      v-if="order[0].dispute_status === 'Admin Approved'">
                    <B>Admin Approved</B>
                  </span>
                        </p>                       
                      </li>
                      <li class="radio active">
                        <p>Refund Method : {{ order[0].refund_method === 1 ? "Bank Refund":"Voucher"}}</p>
                      </li>
                      <li v-if="order[0].refund_method === 1 && order[0].payment_type !== 4" class="radio active">
                        <p>Bank : {{ order[0].bank}}</p>
                      </li>
                      <li  v-if="order[0].refund_method === 1 && order[0].payment_type !== 4" class="radio active">
                        <p>Branch : {{ order[0].branch}}</p>
                      </li>
                      <li  v-if="order[0].refund_method === 1 && order[0].payment_type !== 4" class="radio active">
                        <p>Account Number : {{ order[0].account_number}}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr/>
                 <div class="row">
                  
                </div>
                <div class="row" v-if="order[0].dispute_status === 'merchant reject'">
                  <div class="col-sm-12">
                    <h4>Action</h4>
                    <ul class="product-options clearfix">
                      <li class="radio active" v-if="order[0].dispute_status === 'merchant reject'">
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('send to admin')">Send to Admin</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div v-if="order[0].refund_bank_ref" class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                     <h4>Bank Transaction Details</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Bank ref. Number :  {{ order[0].refund_bank_ref}}</p>
                      </li>
                      <li class="radio active">
                        <p>Bank Slip Image :</p>
                      </li>
                      <a :href="order[0].refund_bank_slip" target="_blank">
                        <img :src="order[0].refund_bank_slip" alt="" height="auto" width="auto">
                      </a>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          </div>
          
          <div class="bottom-form">
						<div class="row">
							<!-- <div class="col-sm-1">
              </div> -->
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ order[0].order_line_id.qty }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ order[0].order_line_id.unit_price }}</h4>
              </div>
              <div class="col-sm-3">
                <label>Shipping and Handling fee</label>
                <h4>Rs. {{ order[0].order_line_id.delivery_charge}}</h4>
              </div>
              <div class="col-sm-3">
                <label>Voucher/Coupon Discount</label>
                <h4>Rs. {{ order[0].order_line_id.coupon_discount_amount + order[0].order_id.voucher_discount_amount }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Total</label>
                <h4>Rs. {{ ((order[0].order_line_id.unit_price * order[0].order_line_id.qty) + order[0].order_line_id.delivery_charge  - (order[0].order_line_id.coupon_discount_amount + order[0].order_id.voucher_discount_amount)) > 0 ? (order[0].order_line_id.unit_price * order[0].order_line_id.qty) + order[0].order_line_id.delivery_charge  - (order[0].order_line_id.coupon_discount_amount + order[0].order_id.voucher_discount_amount) : "0.00" }}</h4>
              </div>
            </div>
					</div>

          </div>

        </div>
      </div>
      <!-- User Loan Modal -->
      <div class="modal" id="mdlDisputeChat" tabindex="-1" role="dialog" style="right:0px !important; top: auto; bottom: 0px;  left:auto;">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="border-radius: 0px !important; ">
            <div class="modal-body" style="padding:0px;">
              <div class="row">
                <ChatModal v-bind:title="'Dispute Chat'" v-bind:data="this.chatdata" /> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- User Loan Modal End --> 
  </div>  
</template>
<script>
import mixinOrder from "@/mixins/buyer/APIOrder"
import ReturnTrackOrder from "./ReturnTrackOrder"

import ChatModal from '@/components/common/chat_modal'

export default {
  mixins: [mixinOrder],
  components: {
    ReturnTrackOrder,
    ChatModal
  },
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Return Order List",
          href: "/buyer-return-orders",
          active: false
        },
        {
          text: "Return Order View",
          href: "#",
          active: true
        }
      ],
      order: [],
      chatdata: []
    }
  },
  computed: {
    oid() {
      let url = window.location.href.split("buyer-return-order-configure-view/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getOrderDetails()
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrderDetails: async function() {
      try {
        let responce = await this.getReturnOrderById(this.oid);
        this.order = responce;

        if (this.order[0]) {
          if (this.order[0].dispute_status === 1) {
            this.order[0].dispute_status = "Dispute Opened"
            } else if (this.order[0].dispute_status === 2) {
            this.order[0].dispute_status = "Dispute in Progress"
          } else if (this.order[0].dispute_status === 10) {
            this.order[0].dispute_status = "Merchanet Approved"
          } else if (this.order[0].dispute_status === 20) {
            this.order[0].dispute_status = "Merchant Rejected"
          } else if (this.order[0].dispute_status === 30) {
            this.order[0].dispute_status = "Escalated to Cochchi Admin"
          } else if (this.order[0].dispute_status === 31) {
            this.order[0].dispute_status = "Admin in Progress"
          } else if (this.order[0].dispute_status === 40) {
            this.order[0].dispute_status = "Admin Approved"
          } else if (this.order[0].dispute_status === 50) {
            this.order[0].dispute_status = "pickup"
          } else if (this.order[0].dispute_status === 60) {
            this.order[0].dispute_status = "retuen approve"
          } else if (this.order[0].dispute_status === 70) {
            this.order[0].dispute_status = "money pass"
          }

          this.fetchDisputeChat(); 
        }

      } catch (error) {
        console.log(error); 
      }
    },
    changeTrackOrder: function(status) {
      if (status === "send to admin") {
        this.changeStatusToSendToAdmin();
      }
    },
    changeStatusToSendToAdmin: async function () {
       try {
        let object = {};
        object._id = this.order[0]._id;
        object.user_reason = "sdcsdcs";
        let responce = await this.putChangeStatusDeliverdById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to Send to Admin!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    },
    fetchDisputeChat: async function (){
      try { 
        let responce = await this.getDisputeChat(this.order[0]._id);
        this.chatdata = responce;
      } catch (error) {
        console.log(error); 
      }
    },
    sumbitChat: async function (text){
      try {
        let object = {};
        object.product = this.order[0].simple_product_id.product_id._id;
        object.dispute = this.order[0]._id;
        object.question = text;
        let responce = await this.postDisputeChat(object);
        this.fetchDisputeChat();
      } catch (error) {
        console.log(error); 
      }
    }
  }
}
</script>
<style scoped>
.radiocontainer input:checked ~ .checkbackground:after {
display: block;
}

.radiocontainer .checkbackground:after {
top: 0px;
left: 0px;
width: 100%;
height: 100%;
border-radius: 5%;
background:#0096aa75;
border:1px solid #000000;
}


.checkbackground {
position:relative;
border-radius: 5%;
/* border:1px solid #666666; */
background-color: #eee;
padding: 8px 10px;
margin-right:10px;
}

.checkbackground:after {
content: "";
position: absolute;
display: none;
}

/* reset Modal */
@media screen and (min-width: 768px) {
	.modal-dialog {
		width: 600px;  
		margin: 0px auto !important;
	}

} 
</style>

