<template>
  <div>
    <div class="col-md-12" style="height: 50px;
							background-color: #025ba6;
							color: white; "  >
      <a type="button" class="close" data-dismiss="modal" aria-label="Close" style="font-size: 40px;
      font-weight: 100;
      padding: 5px;
      color: white;
      opacity: 1;"><span aria-hidden="true">&times;</span></a>
      <h4 class=" " style="margin-top:10px; padding:5px;"><strong>{{title}}</strong></h4>					 
    </div>
    <div class="col-md-12" style="padding:5px;" >
      <!-- Inquires / chat -->
			<div class="" id="inquire" style="margin-top:0px; margin-left:12px; margin-right:12px; margin-bottom:2px;">
			
				<small v-if="this.$store.state.buyer_accessToken === null" ><strong>Login</strong> or <strong>Register</strong> to ask question</small>
				<!-- <div class="buttons">
					<div class="pull-right">I have read and agree to the <a href="#" class="agree"><b>Terms And Conditions</b></a>&nbsp;
						<input class="box-checkbox" required type="checkbox" name="agree"> &nbsp;
					</div>
				</div> -->
				<div  style="max-height: 500px;overflow-y: auto;overflow-x: hidden;">
					<!-- <div style="margin:5px 0 5px 0; border-top:1px solid #eee;" v-if="this.$store.state.buyer_accessToken !== null"></div> -->
					 
           <div v-for="item in data" v-bind:key="item.key">
            <div v-if="item.answer">
              <div style="margin:5px 0 5px 0; border-top:1px solid #eee;"></div>
              <div class="row" style="margin-top:15px; margin-bottom:15px;" v-if="item.question">
                <div class="col-md-2 text-center" style="padding-right:0px;"><img :src="item.user.image" style="width:40px; height:40px; border-radius:50px;"></div>
                <div class="col-md-10" style="padding-left:0px;">
                  <p class="m0p0" style="color:#000;">{{item.question}}</p>
                  <small>Username : {{item.user.first_name}}</small>
                </div>
              </div>
              <div class="row" style="margin-top:15px; margin-bottom:15px;">
                <div class="col-md-10" style="text-align:right;">
                  <p class="m0p0" style="color:#000;">{{item.answer}}</p>
                  <small>Shop : {{item.store_name}} </small>
                </div>
                <div class="col-md-2"><img :src="item.merchant.image" style="width:40px; height:40px; border-radius:50px;"></div>
              </div>
            </div>
            <div v-else>
              <div class="row" style="margin-top:15px; margin-bottom:15px;">
                <div class="col-md-2 text-center" style="padding-right:0px;"><img :src="item.user.image" style="width:40px; height:40px; border-radius:50px;"></div>
                <div class="col-md-10" style="padding-left:0px;">
                  <p class="m0p0" style="color:#000;">{{item.question}}</p>
                  <small>Username : {{item.user.first_name}}</small>
                </div>
              </div>
            </div>
          </div>
				</div>
				

        <div class="row">
          <div class="col-md-12" style="padding-top: 5px;">
            <form class="form-inline">
              <!-- <button type="submit" class="btn btn-default">Send</button>
              &nbsp;
                <textarea class="form-control" rows="1"></textarea>
              &nbsp;
              <button type="submit" class="btn btn-default">Send</button> -->
              <div class="form-group" style="width:100%;">
                <div class="input-group" style="width:100%;">
                <!-- <div class="input-group-addon"><i class="fa fa-paperclip"></i></div> -->
                <textarea class="form-control" v-model="inputtext" placeholder="Type Your Message" rows="1"></textarea>
                <div class="input-group-addon" @click="clickSend()"><i class="fa fa-paper-plane"></i></div>
                </div>
              </div>
            </form>
          </div>
        </div>
			</div>
			<!-- Inquires / chat end -->
      
      <!-- <h3  style="background-color: #ffffff;"><span> SLT </span><img src="https://www.slt.lk/sites/all/themes/slt/images-home/slt-log.svg" style="top: 0; height: 40px; width: 40px; position: absolute;"><i class="fa fa-plus-square" style="right: 0; background: #ffffff; color: #666666; font-size: 28px;"></i></h3>		 -->
    </div>
  </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/buyer/APIProfile";
import { async } from 'q';
export default {
  mixins: [mixinCommon, mixinProfile],
  props: {
    title: String,
    data: Array
  },
  data: function () {
    return {
      loandata: [
        {
          logo: "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1593920318/slt/portal_user/1593920318516.png",
          title: 'SLT',
          selected: false,
          show: true,
        },
        {
          logo: "https://upload.wikimedia.org/wikipedia/en/f/f8/Mobitel_logo.png",
          title: 'Mobitel',
          selected: false,
          show: true,
        }
      ],
      questionArray: [
        {
          key: '',
          
          first_name: '',
          question: '',
          user: {
            image: '',
          }
        }
      ],
      inqform:{
        product: "",
        question: ""
      },
      inputtext: ""
    }
  },
  created(){ 
  },
  methods: {
    clickSend(){
      this.$parent.sumbitChat(this.inputtext);
      this.inputtext = "";
    },
    submitProductInquiry: async function(e){
        e.preventDefault();	
          try {
            
          } catch (error) {
            this.$swal.fire({
            position: 'center',
            type: 'error',
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500
            })
          }
    },
  }
}
</script>
